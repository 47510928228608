import React from 'react'
import './card-insurance-styles.scss'
import { getUrlMedia } from '../../../helpers'

const CardInsurance = (props) => (
  <div
    className="cardInsurance__container"
    style={{
      backgroundImage: `url(${getUrlMedia(props.image)})`,
    }}
  >
    <div className="cardInsurance__content">
      <div className="cardInsurance__box">
        <h2>{props.title}</h2>
        <p>{props.description}</p>
      </div>
    </div>
  </div>
)

export default CardInsurance
