/* eslint-disable max-len */
import _ from 'lodash'
import { Link } from 'gatsby'
import React, { useState, useEffect } from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { useTranslation } from 'react-i18next'
import './insurance-basics-style.scss'
import CardInsiurance from './components/card-insurance'
import ArrowIconRight from '../../assets/icons/pacificcross_icons_arrow-right.svg'
import { getUrlMedia } from '../../helpers'
import {
  ImageRoundCorners,
  StickyMenu,
  Spinner,
  CarouselCards,
  Seo,
} from '../../components'

const InsuranceBasicView = ({ loading, data }) => {
  const [menu, setMenu] = useState([])
  const [tabSelected, setTabSelected] = useState(0)
  const { t } = useTranslation()
  const getMenu = () => {
    const newMenu = _.reduce(
      data.topics,
      (result, value) => {
        result.push({
          id: value.id,
          name: value.title,
        })
        return result
      },
      [],
    )
    setMenu(newMenu)
  }

  useEffect(() => {
    getMenu()
  }, [data])

  const buildMenu = _.reverse(
    _.reduce(
      data.topics,
      (result, value, key) => {
        if (key % 2) {
          result[0].push(value)
          return result
        }
        result[1].push(value)
        return result
      },
      [[], []],
    ),
  )

  const buildTabs = _.reduce(
    _.get(data, 'section_1.coverage'),
    (result, value, key) => {
      result.push({
        key,
        name: value.title,
      })
      return result
    },
    [],
  )

  const onGoToTab = (i) => {
    setTabSelected(i)
  }

  if (loading) {
    return <Spinner loading={loading} />
  }

  return (
    <div className="container-fluid p-0">
      <Seo title={`${t('seo.title')} Insurance basics`} />
      <div className="container-xxl">
        <div className="insurance__container__title">
          <h1 className="title-page">{t('insurance.title')}</h1>
        </div>
        <div className="insurance__block">
          <div className="insurance__topics col-12 col-md-6">
            <h3>{t('insurance.topics')}</h3>
            <div className="row">
              {_.map(buildMenu, (menuItiem, i) => (
                <div key={i} className="col-12 col-md-6">
                  {_.map(menuItiem, (itm, ixx) => (
                    <p key={ixx}>
                      <AnchorLink
                        to={`/insurance-basics/#${itm.id}`}
                        // title="Our team"
                        title=""
                      >
                        {itm.title}
                      </AnchorLink>
                    </p>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <StickyMenu t={t} menu={menu} />

      <div className="container-xxl">
        <div
          className="insurance__section row"
          id={_.get(data, 'section_1.id')}
        >
          <div className="col-12 col-md-9">
            <h2>{_.get(data, 'section_1.title')}</h2>
            <p>{_.get(data, 'section_1.description')}</p>
          </div>
          <div className="insurance__slider">
            <CarouselCards
              tag={t('insurance.some')}
              data={_.get(data, 'section_1.coverage')}
              arrows={false}
              inner={CardInsiurance}
              sToShow={1}
              goToItem={tabSelected}
              styleMarginCards={'0px'}
              sToScroll={1}
            />
            <div className="insurance__section__tabs">
              {_.map(buildTabs, (tab, idx) => (
                <p
                  className={idx === tabSelected && 'active__tab'}
                  onClick={() => onGoToTab(tab.key)}
                  key={idx}
                >
                  {tab.name}
                </p>
              ))}
            </div>
          </div>
        </div>
        <div className="insurance__section" id={_.get(data, 'section_2.id')}>
          <div>
            <h2>{_.get(data, 'section_2.title')}</h2>
            {_.map(_.get(data, 'section_2.data'), (work, idx) => (
              <div key={idx} className="insurance__card__work row">
                <div className="col-12 col-md-3">
                  <span>0{idx + 1}</span>
                </div>
                <div className="col-12 col-md-9">
                  <div
                    className="rich-text"
                    dangerouslySetInnerHTML={{
                      __html: work.text,
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="insuranceSection__background"
        id={_.get(data, 'section_3.id')}
      >
        <div className="insurance__section how container-xxl" id="decide">
          <div>
            <h2>{_.get(data, 'section_3.title')}</h2>
            <div
              className="rich-text"
              dangerouslySetInnerHTML={{
                __html: _.get(data, 'section_3.data'),
              }}
            />
          </div>
        </div>
      </div>
      {/* <div className="container-xxl">
        <div
          className="insurance__section why"
          id={_.get(data, 'section_4.id')}
        >
          <div className="row">
            <div className="col-12 col-md-6">
              <ImageRoundCorners src={getUrlMedia(_.get(data, 'section_4.image'))} roundTopLeft={true} />
            </div>
            <div className="col-12 col-md-6">
              <h2>{_.get(data, 'section_4.title')}</h2>
              <div
                className="rich-text"
                dangerouslySetInnerHTML={{
                  __html: _.get(data, 'section_4.description'),
                }}
              />
              <ul>
                {_.map(_.get(data, 'section_4.products'), (item) => (
                  <li>
                    <Link to={`/product/${item.slug}`}>
                      <span>{item.name}</span> <ArrowIconRight width="20" />
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default InsuranceBasicView
